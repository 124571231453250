import { Type } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { DialogRef, IDialogRef } from '../components/dialog-ref';
import { DialogAbstract } from '../components/dialog.abstract';
import { IAlertDialogData } from '../components/shared/alert-dialog/alert-dialog.data';

/**
 * 대화창 서비스
 */
export abstract class DialogService {
  dialogRefList: DialogRef<any>[] = [];

  constructor() {}

  /**
   * Dialog 생성 및 표시
   */
  abstract open<T extends DialogAbstract, R = any, D = any>(
    component: Type<T>,
    data?: D,
    options?: Partial<any>,
  ): IDialogRef<T, R, D>;

  /**
   * Dialog 생성
   */
  abstract create<T extends DialogAbstract, R = any, D = any>(
    component: Type<T>,
    data?: D,
    options?: Partial<any>,
  ): IDialogRef<T, R, D>;

  close(dialogRef: IDialogRef<any>): void {
    dialogRef.onDialogClosed
      .pipe(
        tap(() => {
          this.dialogRefList.splice(
            this.dialogRefList.indexOf(dialogRef as DialogRef<any>),
            1,
          );
        }),
      )
      .subscribe();
    dialogRef?.close();
  }

  abstract openAlertDialog(
    data: IAlertDialogData,
    options?: Partial<any>,
  ): Observable<any>;

  alert(
    message: string,
    title?: string | null,
    options?: Partial<any>,
  ): Observable<void> {
    return this.openAlertDialog(
      { title, message, positiveButton: {} },
      options,
    );
  }

  /**
   * 예/아니오 창
   * @param title 상단 내용
   * @param message 본문 내용
   * @param config data 제외한 설정 내용
   */
  confirm(
    message: string,
    title?: string | null,
    options?: Partial<any>,
  ): Observable<boolean | null> {
    return this.openAlertDialog(
      {
        title,
        message,
        positiveButton: {},
        negativeButton: {},
      },
      options,
    );
  }

  /**
   * 오류 표시 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param config data 제외한 설정 내용
   */
  openErrorAlert(
    error: string,
    message?: string,
    config?: any,
  ): Observable<any> {
    throw new Error('Not implemented.');
  }
}
